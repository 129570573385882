@font-face {
    font-family: 'Marlide Display Bold';
    src: url('./fonts/Marlide-Display-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PP Neue Montreal';
    src: url('./fonts/ppneuemontreal-book.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

html,body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: 'PP Neue Montreal';
  font-weight: 400;
}

.App {
  text-align: center;
  color: #fff;
  background-color: '#000';
  background-image: url('images/pattern.png');
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  background-attachment: fixed;
  width: 100%;
}

.App-basic {
  text-align: center;
  background-color: '#000';
  color: #fff;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p.highlighted-amount span {
  white-space: nowrap;
  color: #FFA900;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 59%;
  background-origin: content-box;
  background-position: 166px 108px;
  display: inline-block;
  font-weight: bold;
}

h3, h4, h5, h6 {
  font-family: 'Marlide Display Bold';
}

p.highlighted-text {
  font-family: 'Marlide Display Bold';
}

p.highlighted-text span {
  font-family: 'Marlide Display Bold';
  color: #FFA900;
  display: inline-block;

  /* background: url("localhost:3000/static/media/lightning-animation.4b145581.gif") repeat center;
  background-size: cover;
  background-position: center;
  -webkit-background-clip: text;
  background-repeat: repeat-y; */

  animation: pulse-highlight 6s;


  letter-spacing: 1px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@keyframes pulse-highlight {
  0% {
    transform: scale(0.90);
    color: 0 0 0 0 rgba(255, 217, 63, 0.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    color: 0 0 0 10px rgba(255, 217, 63, 0);
    opacity: 1.0;
  }
}

.Home-s{
  display: flex;
  flex-direction: row;
}

.Intro {
  position: relative;
}

.Home-s1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin-top: 200px;
  margin-bottom: 200px;
}

.Home-s2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  min-width: 50%;
}

.Home-section1 {
  font-size: calc(10px + 2vmin);
  background-color: #000;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.Home-section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Home-section3 {
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-top: 50px;
}

.Home-section4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-top: 200px;
  height: 500px;
  padding-bottom: 130px;
}

.App-subheader {
  margin-top: 25px;
  margin-bottom: 50px;
  width: 50%;
}

.App-footer {
  background-image: url('images/pattern.png');
  background-color: #000;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100px;
  width: 100%;
  bottom: 0;
}

.App-link {
  color: #61dafb;
}

/* add blur effect to dropdown menu */
.collapse.show {
  backdrop-filter: blur(6px);
}

.appStores {
  align-items: center;
}

.appStores img {
  padding: 10px;
}

.appStores2 {
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.appStores2 img {
  padding: 10px;
}

.appStores3 {
  align-items: center;
  padding-top: 20px;
  padding-bottom: 120px;
  display: inline-block;
}

.appStores3 img {
  padding: 10px;
}

.appScreenshots {
  max-width: 1200px;
  margin-top: 100px;
  margin-bottom: 0px;
}

.whiteServices {
  max-width: 1200px;
  margin-top: 100px;
  margin-bottom: 0px;
}

.whiteServices h3 {
  margin-top: 10px;
  margin-bottom: 50px;
}

.whiteServices img {
  max-width: 120px;
}

.whiteServices .highlighted-text {
  font-size: 28px;
  line-height: 32px;
}

.whiteServices p {
  font-size: 24px;
  line-height: 30px;
}

.screenshot { 
  margin-bottom: 40px;
}

.screenshot p {
  margin-left: 30px;
  margin-right: 30px;
}

.screenshot img {
  width: 300px;
  margin-bottom: 35px;
}

.integrations {
  max-width: 1200px;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 120px;
}

.integrations img {
  max-width: 100%;
}

.integrations p {
  margin-left: 25px;
  margin-right: 25px;
}

.features {
  margin-bottom: 150px;
}

.features p {
  margin-left: 25px;
  margin-right: 25px;
}

.social img {
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: 20px;
}

.social2 img {
  margin: 20px;
}

.h1, h1 {
  color: #fff;
  font-style: normal;
  font-weight: 800;
  font-size: 100px;
  line-height: 105px;
  text-align: center;
  letter-spacing: 0.02em;
  max-width: 850px;
}

.h2, h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 74px;
  line-height: 85px;
  text-align: center;
  letter-spacing: 0.02em;
  max-width: 700px;
}

.h3, h3 {
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  display: block;
}

.h4, h4 {
  font-style: normal;
  font-weight: 800;
  font-size: 45px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  display: block;
}

.h5, h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  display: block;
  align-items: center;
}

.h6, h6 {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  display: block;
  align-items: center;
}

.fdroid {
  align-items: center;
  align-self: center;
}

code {
	font-family: monospace;
	font-size: .9rem;
	background: #000;
	color: #fff;
	padding: .3rem .4rem .3rem .4rem;
	border-radius: 3px;
  margin-bottom: 10px;
  margin-top: 5px;
}

code::before {
  content: attr(data-text);
  background: black;
  padding: 1px 0px;
}

nav.navbar.navbar-expand-lg.navbar-dark.bg-dark {
  background-color: #000 !important;
}

.navbar-dark .navbar-nav .nav-link {
	color: white;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 40px;
}

a {
	text-decoration: none;
  color: #FFA900;
}

.createdBy {
  margin-top: 30px;
}

.downloadDetails {
  margin-top: 10px;
  font-size: 15px;
}

.About-section {
    padding-top: 40px;
    padding-bottom: 40px;
    display: block;
}

.About-text {
    color: darkgrey;
    margin: 50px 100px;
}

.Downloads {
  margin-top: 150px;
  margin-bottom: 100px;
}

.Split {
    overflow: hidden;
    width: 50%;
    margin: auto;
}

.Split section {
    width: 50%;
    min-width: 100px;
    float: left;
    display: block;
}

.Split section:left {
    float: left;
}

.right {
    margin-top: 120px;
}

.hyper {
  height: 100%;
  object-fit: cover;
}

.Zeus-intro {
    margin-top: 200px;
    align-items: center;
    align-self: center;
}

.Zeus-intro-text {
    padding: 20px;
}

.sectionContent {
    align-content: center;
    width: 80%;
    margin: 50px auto;
    padding-bottom: 30px;
}

.avatarDisplay {
    margin-top: 50px;
}

.avatar {
    display: inline-block;
    border-radius: 50%;
    margin: 10px;
}

.avatar:hover {
    box-shadow: 0px 0px 5px 8px rgb(255, 218, 70);
    transition: .4s all;
}

.donateButton {
    margin-top: 25px;
}

.Donate {
    background-color: #000;
    padding-top: 100px;
    padding-bottom: 100px;
}

.Donation-types {
    align-content: center;
    margin: 0 auto;
}

.Donation-type {
    color: #fff;
    min-height: 500px;
    width: 360px;
    max-width: 100%;
    border-radius: 360px;
    background-repeat: no-repeat;
    background-position: center;
    border-width: 0px;
    background-color: transparent;
    background-image: url('images/wreath.png');
    background-size: 100%;
}

.Donation-type:hover {
  height: 360px;
  width: 360px;
  border-radius: 360px;
  transition: .2s all;
  background-image: url('images/wreath-highlight.png');
}

.Donation-type-highlighted {
  color: #fff;
  height: 500px;
  width: 491px;
  max-width: 100%;
  border-radius: 360px;
  background-color: transparent;
  background-image: url('images/wreath-highlight.png'), url('images/glow.png');
  background-size: 100%;
  border-width: 0px;
  background-position: center;
  background-repeat: no-repeat;
}

.Donation-description {
  margin: 0 100px;
}

span.twitter-handle {
  padding: 10px;
  padding-right: 10px;
}

span.amount {
  background-color: #fff;
  color: #fff;
}

p.amount {
  font-size: 20px;
  font-weight: 800;
}

.PrivacyPolicy {
  padding-top: 150px;
  padding-bottom: 150px;
  margin: 30px;
  text-align: left;
}


.PrivacyPolicyText {
    font-size: 20px;
    margin-top: 40px;
}

.canary {
    background-color: #000;
    padding-bottom: 20px;
}

.white-banner {
    position: relative;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
}

.white-banner .overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: url('images/temple.jpeg');
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Add the blur effect */
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.white-logo {
  z-index: 3;
  margin-top: -100px;
}

.white-logo img {
  max-width: 90%;
}

.white-logo h2 {
  font-family: 'Marlide Display Bold';
  font-weight: 400;
  font-size: 60px;
}

.white-banner #text {
  position: relative;
  font-size: 12em;
  color: #fff;
  flex: 1;
  flex-direction: column;
}

.White-content {
  background-color: #fff;
  justify-content: center;
  display: flex;
  position: relative;
}

.White-section1 {
  max-width: 1200px;
  background-color: #fff;
  color: #000;
  font-size: 24px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
  padding: 20px;
  padding-right: 20px;
}

.white-text {
  text-align: left;
  margin-bottom: 100px;
}

.white-text h3 {
  margin: 60px;
  margin-top: 20px;
}

.white-text h4 {
  font-size: 25px;
  margin: 20px;
  margin-top: 40px;
}

.white-text .customers { 
  max-width: 1200px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
  text-align: center;
}

.white-text .services {
  max-width: 1200px;
  display: flex;
  justify-content: center;
}

.white-text ul {
  align-self: center;
  align-items: center;
}

.white-banner .clouds {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}


.white-banner .clouds img {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 100%;
  animation: animate calc(3s * var(--i)) linear infinite;
}

@keyframes animate
{
  0%
  {
    opacity: 0;
    transform: scale(1);
  }
  25%, 75%
  {
    opacity: 1;
  }
  100%
  {
    opacity: 0;
    transform: scale(3);
  }
}
section {
  position: 
  padding 75px 100px;
}
section h2 {
  position: relative;
  font-size: 2.5em;
  margin-bottom: 20px;
}

/* form */
.form-container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

input[type=email], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 12px 12px 12px 0;
  display: block;
}

input[type=submit] {
  width: 100%;
  margin-top: 30px;
  background-color: #000;
  color: #FFA900;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  font-family: 'PP Neue Montreal';
}

input[type=submit]:hover {
  color: #000;
  background-color: #FFA900;
}

input[type=submit]:disabled {
  background-color: #000;
  color: #FFA900;
  opacity: 0.5;
  cursor: not-allowed;
}

.contactForm {
  margin-top: 80px;
  margin-bottom: 140px;
  text-align: left;
}

.form-container {
  margin-top: 30px;
}

.subtitle {
  font-size: 22px;
}

/* Animation */

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animated2 {
  animation-delay: 0.125s;
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animated3 {
  animation-delay: 0.25s;
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animated4 {
  animation-delay: 0.375s;
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

/* footer */
.footer-content {
  max-width: 800px;
  margin: 0 auto;
}

.footer-list {
  list-style: none; /* Removes the bullets */
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0;
}

.footer-list li {
  font-size: 14px;
}

.footer-list a {
  color: #ddd;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-list a:hover {
  color: #fff;
}